import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "../components/Modal";
import { useModalStore } from "../hooks/useModalStore";

const shareUrl = encodeURIComponent(
  `${window.location.protocol}//${window.location.host}`
);

export function SocialShareLinks() {
  const { t } = useTranslation("common");
  const shareSubject = t("share-subject");
  const shareText = t("share-text");
  const shareHashtag = encodeURIComponent(t("share-hashtag"));
  return (
    <SocialLogoRow
      links={[
        {
          href: `https://twitter.com/intent/tweet?text=${shareText}&url=${shareUrl}&hashtags=${shareHashtag}`,
          src: "images/social/x.svg",
          alt: "X",
        },
        {
          href: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
          src: "images/social/facebook.svg",
          alt: "Facebook",
        },
        {
          href: `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`,
          src: "images/social/linkedin.svg",
          alt: "LinkedIn",
        },
        {
          href: `https://new.reddit.com/submit?url=${shareUrl}&title=${shareText}`,
          src: "images/social/reddit.svg",
          alt: "Reddit",
        },
        {
          href: `mailto:?subject=${shareSubject}&body=${`${shareText} - ${shareUrl}`}`,
          src: "images/social/email.svg",
          alt: "Email",
        },
      ]}
    />
  );
}

export function SocialShareModal() {
  const open = useModalStore((state) => state.socialShareModalIsOpen);
  const closeModal = useModalStore((state) => state.closeSocialShareModal);
  const { t } = useTranslation("common");

  return (
    <Modal open={open} onClose={closeModal} title="Share">
      <div className="flex flex-col max-sm:h-full sm:h-40 p-2">
        <div className="flex justify-center items-center font-600 text-6">
          {t("share-caption")}
        </div>
        <div className="flex justify-center align-middle h-15 max-sm:w-screen max-sm:m-auto sm:w-100 sm:m-5">
          <SocialShareLinks />
        </div>
      </div>
    </Modal>
  );
}

const SocialLogoRow = ({ links }) => (
  <div className="flex flex-wrap items-center justify-center max-sm:max-w-343px">
    {links.map((link, ii) => (
      <div
        key={ii}
        className={`flex justify-center align-middle h-15 w-15 ${
          ii > 0 ? "ml-4" : ""
        }`}
      >
        <a href={link.href} target="_blank" rel="noreferrer">
          <img src={link.src} alt={link.alt} />
        </a>
      </div>
    ))}
  </div>
);

import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "../components/Button";

export function SurveyOffer() {
  const navigate = useNavigate();
  const { t } = useTranslation("survey-offer");

  return (
    <div className="flex flex-col items-center max-sm:w-343px m-auto w-full pt-50">
      <div className="text-center text-4.5 leading-8 font-700">
        {t("question")}
        <br />
        {t("subtitle")}
      </div>

      <div className="flex justify-center pt-4">
        <Button
          secondary
          className="h-57px max-sm:w-42 w-52 max-sm:px-4"
          onClick={() => navigate("/results")}
          text={t("secondary-button-label")}
        />
        <div className="w-4" />
        <Button
          className="h-57px max-sm:w-42 w-52 max-sm:px-4"
          onClick={() => navigate("/survey")}
          text={t("primary-button-label")}
        />
      </div>
    </div>
  );
}

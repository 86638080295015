import React from "react";
import { useBlocker } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Modal } from "../components/Modal";
import { useStore } from "../hooks/useStore";
import { Button } from "../components/Button";

// enableBlockerRef is necessary to turn off the blocker when navigation is allowed
// state did not work in this role because navigation is synchronous but state updates asynchronously
export function BeforeLeaveModal({ enableBlockerRef }) {
  const { t } = useTranslation("before-leave");

  const resetSession = useStore((state) => state.resetSession);

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      enableBlockerRef.current &&
      currentLocation.pathname !== nextLocation.pathname
  );

  const proceedHandler = () => {
    // avoid reseting session while on a page using session
    // allow navigation and reset immediately after
    setTimeout(() => resetSession(), 1);
    if (blocker.state === "blocked") {
      blocker.proceed();
    }
  };

  const cancelHandler = () => {
    if (blocker.state === "blocked") {
      blocker.reset();
    } else {
      console.log("cancel called after blocker reset");
    }
  };

  return (
    <Modal open={blocker.state === "blocked"} onClose={cancelHandler}>
      <div className="h-full max-sm:w-screen sm:w-full overflow-y-auto">
        <div className="w-357px max-sm:h-84svh sm:max-w-568px sm:w-568px flex flex-col justify-center items-center font-sans mx-auto sm:mx-10 sm:my-8">
          <div className="flex flex-col justify-center items-center max-sm:flex-grow ">
            <div className="text-6 leading-8 font-700">{t("title")}</div>
            <div className="text-tinkerTots-black">{t("content")}</div>
            <div className="flex flex-col sm:flex-row justify-between w-full mt-6 max-sm:h-130px">
              <Button
                secondary
                className="w-full sm:w-272px h-57px"
                text={t("leave-button-label")}
                onClick={proceedHandler}
              />
              <Button
                className="w-full sm:w-272px h-57px"
                text={t("cancel-button-label")}
                onClick={cancelHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

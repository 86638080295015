import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Modal } from "../components/Modal";
import { useModalStore } from "../hooks/useModalStore";
import { useStore } from "../hooks/useStore";
import { Button } from "../components/Button";

function TriggerWarning({ onClose }) {
  const { t } = useTranslation("trigger-warning");
  const setTriggerWarningViewed = useStore(
    (state) => state.setTriggerWarningViewed
  );
  const closeTriggerWarningModal = useModalStore(
    (state) => state.closeTriggerWarningModal
  );

  const proceedHandler = () => {
    setTriggerWarningViewed();
    closeTriggerWarningModal();
  };

  return (
    <div className="h-full max-sm:w-screen sm:w-full overflow-y-auto">
      <div className="w-357px max-sm:h-84svh sm:max-w-568px sm:w-568px flex flex-col justify-center items-center font-sans mx-auto sm:mx-10 sm:my-8">
        <div className="flex flex-col justify-center items-center max-sm:flex-grow ">
          <div className="text-6 leading-8 font-700">{t("title")}</div>
          <div
            className="text-center"
            dangerouslySetInnerHTML={{ __html: t("content") }}
          />
        </div>
        <div className="flex flex-col sm:flex-row justify-between w-full mt-6 max-sm:h-130px">
          <Button
            secondary
            className="w-full sm:w-272px h-57px"
            text={t("secondary-button-label")}
            onClick={onClose}
          />
          <Button
            className="w-full sm:w-272px h-57px"
            text={t("primary-button-label")}
            onClick={proceedHandler}
          />
        </div>
      </div>
    </div>
  );
}

export function TriggerWarningModal() {
  const navigate = useNavigate();
  const triggerWarningViewed = useStore((state) => state.triggerWarningViewed);
  const open = useModalStore((state) => state.triggerWarningModalIsOpen);
  const close = useModalStore((state) => state.closeTriggerWarningModal);
  const closeHandler = () => {
    if (!triggerWarningViewed) {
      navigate("/");
    }
    close();
  };

  return (
    <Modal open={open} onClose={closeHandler}>
      <TriggerWarning onClose={closeHandler} />
    </Modal>
  );
}

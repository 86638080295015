import React from "react";

const base =
  "inline-flex items-center px-6 py-2 sm:py-4 justify-center font-700 min-w-156px sm:text-4 sm:h-54px rounded-2 opacity-100";
const disabledClass =
  "disabled:text-tinkerTots-gray-300 disabled:bg-tinkerTots-gray-100 disabled:cursor-not-allowed";
const hover = "hover:(transform scale-[0.96])";
const focus = "focus:(outline-none transform scale-[0.96])";
const prim =
  "text-white bg-tinkerTots-primary-main active:bg-tinkerTots-primary-600";
const sec =
  "text-tinkerTots-primary-main bg-tinkerTots-primary-10 active:text-tinkerTots-primary-600";

const textOnly =
  "text-tinkerTots-primary-main bg-transparent border-none hover:underline active:text-tinkerTots-primary-600";

const textOnlyFocus =
  "focus:(outline-none underline text-tinkerTots-primary-600)";

export function Button({
  text,
  secondary = false,
  onClick,
  autoFocus = false,
  disabled = false,
  className = "",
}) {
  return (
    <button
      type="button"
      className={`${base} ${disabledClass} ${hover} ${focus} ${
        secondary ? sec : prim
      } ${className}`}
      onClick={onClick}
      autoFocus={autoFocus}
      disabled={disabled}
      tabIndex={disabled ? -1 : 0}
    >
      {text}
    </button>
  );
}

export function TextOnlyButton({
  text,
  onClick,
  autoFocus = false,
  disabled = false,
}) {
  return (
    <button
      type="button"
      className={`${base} ${textOnly} ${textOnlyFocus} disabled:text-tinkerTots-gray-300`}
      onClick={onClick}
      autoFocus={autoFocus}
      disabled={disabled}
      tabIndex={disabled ? -1 : 0}
    >
      {text}
    </button>
  );
}

import { useEffect, useState, useRef } from "react";

export function useFlip(){
  const [isFront, setIsFront] = useState(true);
  const [toFront, setToFront] = useState(false);
  const [isFlipping, setIsFlipping] = useState(false);
  const flipTimeRef = useRef(200);

  useEffect(() => {
    if (isFlipping) {
    const start = Date.now();
    const handle = setTimeout(() => {
      setIsFlipping(false);
    }, flipTimeRef.current);

    return () => {
      flipTimeRef.current = Math.max(
      0,
      flipTimeRef.current - (Date.now() - start)
      );
      clearTimeout(handle);
    };
    }
  }, [isFlipping]);

  const flip = () => {
    flipTimeRef.current = 200;
    setIsFlipping(true);
    setToFront(!isFront);
    setIsFront((f) => !f);
  };

  return { flip, toFront, isFront, isFlipping };
}
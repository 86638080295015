import React from "react";
/*
font-family: SF Pro;
font-size: 40px;
font-weight: 510;
line-height: 47.73px;
text-align: center;
*/
export function ArrowRight() {
  // TODO: Wrong symbol and badly centered
  return (
    <div className="bg-tinkerTots-primary-10 p-2 m-2 h-12 w-12 flex justify-center items-center rounded-16">
      <span className=" text-tinkerTots-primary-main font-size-10 font-510 text-center align-middle ">
        {`>`}
      </span>
    </div>
  );
}

export function ArrowLeft() {
  // TODO: Wrong symbol and badly centered
  return (
    <div className="bg-tinkerTots-primary-10 p-2 m-2 h-12 w-12 flex justify-center items-center rounded-16">
      <span className=" text-tinkerTots-primary-main font-size-10 font-510 text-center align-middle">
        {`<`}
      </span>
    </div>
  );
}

export const Dot = ({ active, onClick, disabled }) => {
  // Conditional class or style for disabled state
  const disabledClass = disabled
    ? "cursor-not-allowed pointer-events-none"
    : "";
  const handleClick = (e) => {
    if (!disabled && onClick) {
      onClick(e);
    }
  };

  if (active) {
    return (
      <span
        className={`text-tinkerTots-primary-main p-1 ${disabledClass}`}
        onClick={handleClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <circle cx="6" cy="6" r="6" fill="currentColor" />
        </svg>
      </span>
    );
  }
  return (
    <span
      className={`text-tinkerTots-primary-10 p-1 ${disabledClass}`}
      onClick={handleClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="8"
        viewBox="0 0 9 8"
        fill="none"
      >
        <circle cx="4.5" cy="4" r="4" fill="#EC4441" fillOpacity="0.1" />
      </svg>
    </span>
  );
};

export function Dots({ count, active, onClick }) {
  return (
    <div className="flex justify-center items-center">
      {Array(count)
        .fill()
        .map((_, i) => (
          <Dot
            key={i}
            active={i === active}
            disabled={!onClick}
            onClick={() => onClick(i)}
          />
        ))}
    </div>
  );
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useStore } from "../hooks/useStore";
import { Button } from "../components/Button";
import { Modal } from "../components/Modal";
import { useModalStore } from "../hooks/useModalStore";

function Feedback({ onClose }) {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const createFeedback = useStore((state) => state.createFeedback);
  const { t } = useTranslation("feedback");
  const fromPage = useStore((state) => state.feedbackLocation);
  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    createFeedback({ page: fromPage, message }).then(() => {
      setMessage("");
      setLoading(false);
      onClose();
    });
  };
  return (
    <div className="h-full max-sm:w-screen sm:w-full overflow-y-auto">
      <div className="flex  flex-col justify-start items-start sm:justify-center sm:items-center sm:w-1090px sm:h-444px mx-auto py-8 px-4 sm:px-16">
        <div className="font-sans text-tinkerTots-black flex flex-col h-32 w-full items-start justify-center sm:items-center py-4 sm:py-6">
          <div className="text-6 leading-8 font-700 text-left">
            {t("title")}
          </div>
          <div className="h-1.5 sm:h-2.5 w-full" />
          <div className="text-4 leading-6 font-400 leading-24px text-left">
            {t("subtitle")}
          </div>
        </div>

        <form
          className="flex flex-col justify-center items-center w-full"
          onSubmit={submit}
        >
          <textarea
            className="w-full h-48 sm:h-50 sm:w-870px border border-tinkerTots-black rounded-2 p-4 text-4 leading-6 font-400 leading-24px text-left outline-none focus:outline-tinkerTots-primary-600 focus:border-none"
            placeholder={t("placeholder")}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="flex justify-end items-center h-32 w-full ">
            <Button
              text={t("button-label")}
              type="submit"
              disabled={loading || !message}
              onClick={submit}
              className="max-sm:w-full max-sm:h-14"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export function FeedbackModal() {
  const open = useModalStore((state) => state.feedbackModalIsOpen);
  const close = useModalStore((state) => state.closeFeedbackModal);

  return (
    <Modal open={open} onClose={close}>
      <Feedback onClose={close} />
    </Modal>
  );
}

export const NUM_FAQS = 8;
export const NUM_CONSENT_PARAGRAPHS = 7;
export const NUM_PAIRS = 6;
export const NUM_COMPARISONS = 8;
export const NUM_COMPARISON_SELECTIONS = 3;
export const NUM_INSTRUCTION_STEPS = 4;
export const ESSENTIAL_SURVEY_QUESTIONS = [10, 11, 12, 13, 15, 17, 18];
export const NUM_SURVEY_QUESTIONS_TO_ASK = 12;
export const NUM_SURVEY_QUESTIONS = 23;
export const ESSENTIAL_ODDS = 9;
export const SECONDARY_ODDS = 1;
export const CONDITION_ODDS = 3;
export const TRAIT_ODDS = 2;
export const PROBABILITY_PAIRS_FIRST = 0.9;
export const PROBABILITY_SURVEY_FIRST = 0.5;
export const OTHER_RESULTS_CACHE_LIMIT_MS = 1000 * 60 * 60 * 6;

export const CARD_SECTIONS = [
  "gender",
  "trait",
  "condition",
]

export enum Genders {
  "MALE" = "male",
  "FEMALE" = "female",
};

export enum AttributeTypes {
  TRAIT = "trait",
  CONDITION = "condition",
};

export enum Attributes {
  "GOOD_AT_DELAYING_GRATIFICATION" = "Good at Delaying Gratification",
  "AGREEABLENESS" = "Agreeableness",
  "ANTISOCIAL_BEHAVIOUR" = "Antisocial Behaviour",
  "CONSCIENTIOUSNESS" = "Conscientiousness",
  "EXTRAVERSION" = "Extraversion",
  "HIGH_CREATIVITY" = "High Creativity",
  "HIGH_EMOTIONAL_INTELLIGENCE" = "High Emotional Intelligence",
  "HIGH_IQ" = "High IQ",
  "HIGH_MUSICAL_TALENTS" = "High Musical Talents",
  "HIGH_PHYSICAL_FITNESS" = "High Physical Fitness",
  "HIGH_PHYSICAL_RESILIENCE" = "High Physical Resilience",
  "INTERSEX_TRAITS" = "Intersex Traits",
  "NEUROTICISM" = "Neuroticism",
  "OPENNESS_TO_NEW_EXPERIENCES" = "Openness to New Experiences",
  "PHYSICALLY_ATTRACTIVE" = "Physically Attractive",
  "PHYSICALLY_TALL" = "Physically Tall",
  "PROSOCIAL_BEHAVIOUR" = "Prosocial Behaviour",
  "RELIGIOUS" = "Religious",
  "RISK_TAKING_BEHAVIOUR" = "Risk Taking Behaviour",
  "SAME_SEX_ATTRACTION" = "Same Sex Attraction",
  "ADHD" = "ADHD",
  "ALZHEIMERS" = "Alzheimers",
  "ANXIETY" = "Anxiety",
  "AUTISM" = "Autism",
  "BIPOLAR" = "Bipolar",
  "CLINICAL_OBESITY" = "Clinical Obesity",
  "DEPRESSION" = "Depression",
  "DIABETES" = "Diabetes",
  "DYSLEXIA" = "Dyslexia",
  "HEART_DISEASE" = "Heart Disease",
  "HEARING_IMPAIRMENT" = "Hearing Impairment",
  "OBSESSIVE_COMPULSIVE_DISORDER" = "Obsessive Compulsive Disorder",
  "SCHIZOPHRENIA" = "Schizophrenia",
  "VISION_IMPAIRMENT" = "Vision Impairment",
}

export enum ChanceLevels {
  SIGNIFICANTLY_ABOVE_AVERAGE = "significantly above average",
  SLIGHTLY_ABOVE_AVERAGE = "slightly above average",
  MODERATELY_ABOVE_AVERAGE = "moderately above average",
  AVERAGE = "average",
  SLIGHTLY_BELOW_AVERAGE = "slightly below average",
  MODERATELY_BELOW_AVERAGE = "moderately below average",  
  SIGNIFICANTLY_BELOW_AVERAGE = "significantly below average",
};

export enum Preceding {
  HAVING = "having",
  BEING = "being",
  NONE = "none",
};

export const genderImages = {
  [Genders.MALE]: "images/genders/male.svg",
  [Genders.FEMALE]: "images/genders/female.svg",
};

export const attributesMap = {
  [Attributes.GOOD_AT_DELAYING_GRATIFICATION]: {type: AttributeTypes.TRAIT, label: "Good at Delaying Gratification", priority: "essential", image: "images/traits/good-at-delaying-gratification.svg", preceding: Preceding.BEING},
  [Attributes.AGREEABLENESS]: {type: AttributeTypes.TRAIT, label: "Agreeableness", priority: "secondary", image: "images/traits/agreeableness.svg", preceding: Preceding.NONE},
  [Attributes.ANTISOCIAL_BEHAVIOUR]: {type: AttributeTypes.TRAIT, label: "Antisocial Behaviour", priority: "essential", image: "images/traits/antisocial-behaviour.svg", preceding: Preceding.NONE},
  [Attributes.CONSCIENTIOUSNESS]: {type: AttributeTypes.TRAIT, label: "Conscientiousness", priority: "secondary", image: "images/traits/conscientiousness.svg", preceding: Preceding.NONE},
  [Attributes.EXTRAVERSION]: {type: AttributeTypes.TRAIT, label: "Extraversion", priority: "essential", image: "images/traits/extraversion.svg"},
  [Attributes.HIGH_CREATIVITY]: {type: AttributeTypes.TRAIT, label: "High Creativity", priority: "essential", image: "images/traits/high-creativity.svg"},
  [Attributes.HIGH_EMOTIONAL_INTELLIGENCE]: {type: AttributeTypes.TRAIT, label: "High Emotional Intelligence", priority: "essential", image: "images/traits/high-emotional-intelligence.svg"},
  [Attributes.HIGH_IQ]: {type: AttributeTypes.TRAIT, label: "High IQ", priority: "essential", image: "images/traits/high-iq.svg"},
  [Attributes.HIGH_MUSICAL_TALENTS]: {type: AttributeTypes.TRAIT, label: "High Musical Talents", priority: "essential", image: "images/traits/high-musical-talents.svg"},
  [Attributes.HIGH_PHYSICAL_FITNESS]: {type: AttributeTypes.TRAIT, label: "High Physical Fitness", priority: "essential", image: "images/traits/high-physical-fitness.svg"},
  [Attributes.HIGH_PHYSICAL_RESILIENCE]: {type: AttributeTypes.TRAIT, label: "High Physical Resilience", priority: "secondary", image: "images/traits/high-physical-resilience.svg"},
  [Attributes.INTERSEX_TRAITS]: {type: AttributeTypes.TRAIT, label: "Intersex Traits", priority: "secondary", image: "images/traits/intersex-traits.svg"},
  [Attributes.NEUROTICISM]: {type: AttributeTypes.TRAIT, label: "Neuroticism", priority: "secondary", image: "images/traits/neuroticism.svg", preceding: Preceding.NONE},
  [Attributes.OPENNESS_TO_NEW_EXPERIENCES]: {type: AttributeTypes.TRAIT, label: "Openness to New Experiences", priority: "secondary", image: "images/traits/openness-to-new-experiences.svg", preceding: Preceding.NONE},
  [Attributes.PHYSICALLY_ATTRACTIVE]: {type: AttributeTypes.TRAIT, label: "Physically Attractive", priority: "essential", image: "images/traits/physically-attractive.svg", preceding: Preceding.BEING},
  [Attributes.PHYSICALLY_TALL]: {type: AttributeTypes.TRAIT, label: "Physically Tall", priority: "essential", image: "images/traits/physically-tall.svg", preceding: Preceding.BEING},
  [Attributes.PROSOCIAL_BEHAVIOUR]: {type: AttributeTypes.TRAIT, label: "Prosocial Behaviour", priority: "essential", image: "images/traits/prosocial-behaviour.svg", preceding: Preceding.NONE},
  [Attributes.RELIGIOUS]: {type: AttributeTypes.TRAIT, label: "Religious", priority: "essential", image: "images/traits/religious.svg", preceding: Preceding.BEING},
  [Attributes.RISK_TAKING_BEHAVIOUR]: {type: AttributeTypes.TRAIT, label: "Risk Taking Behaviour", priority: "secondary", image: "images/traits/risk-taking-behaviour.svg", preceding: Preceding.NONE},
  [Attributes.SAME_SEX_ATTRACTION]: {type: AttributeTypes.TRAIT, label:"Same-sex Attraction", priority: "essential", image: "images/traits/same-sex-attraction.svg"},
  [Attributes.ALZHEIMERS]: {type: AttributeTypes.CONDITION, label: "Alzheimers", priority: "essential", image: "images/conditions/alzheimers.svg"},
  [Attributes.ANXIETY]: {type: AttributeTypes.CONDITION, label: "Anxiety", priority: "secondary", image: "images/conditions/anxiety.svg"},
  [Attributes.ADHD]: {type: AttributeTypes.CONDITION, label: "ADHD", priority: "essential", image: "images/conditions/adhd.svg"},
  [Attributes.AUTISM]: {type: AttributeTypes.CONDITION, label: "Autism", priority: "essential", image: "images/conditions/autism.svg"},
  [Attributes.BIPOLAR]: {type: AttributeTypes.CONDITION, label: "Bipolar", priority: "essential", image: "images/conditions/bipolar.svg"},
  [Attributes.CLINICAL_OBESITY]: {type: AttributeTypes.CONDITION, label: "Clinical Obesity", priority: "essential", image: "images/conditions/clinical-obesity.svg"},
  [Attributes.DEPRESSION]: {type: AttributeTypes.CONDITION, label: "Depression", priority: "essential", image: "images/conditions/depression.svg"},
  [Attributes.DIABETES]: {type: AttributeTypes.CONDITION, label: "Diabetes", priority: "secondary", image: "images/conditions/diabetes.svg"},
  [Attributes.DYSLEXIA]: {type: AttributeTypes.CONDITION, label: "Dyslexia", priority: "secondary", image: "images/conditions/dyslexia.svg"},
  [Attributes.HEARING_IMPAIRMENT]: {type: AttributeTypes.CONDITION, label: "Hearing Impairment", priority: "essential", image: "images/conditions/hearing-impairment.svg"},
  [Attributes.HEART_DISEASE]: {type: AttributeTypes.CONDITION, label: "Heart Disease", priority: "essential", image: "images/conditions/heart-disease.svg"},
  [Attributes.OBSESSIVE_COMPULSIVE_DISORDER]: {type: AttributeTypes.CONDITION, label: "Obsessive Compulsive Disorder", priority: "essential", image: "images/conditions/obsessive-compulsive-disorder.svg"},
  [Attributes.SCHIZOPHRENIA]: {type: AttributeTypes.CONDITION, label: "Schizophrenia", priority: "secondary", image: "images/conditions/schizophrenia.svg"},
  [Attributes.VISION_IMPAIRMENT]: {type: AttributeTypes.CONDITION, label: "Vision Impairment", priority: "essential", image: "images/conditions/vision-impairment.svg"},
}

export const chanceLevelImages = {
  [ChanceLevels.SIGNIFICANTLY_ABOVE_AVERAGE]: "images/chanceLevels/significantly-above-average.svg",
  [ChanceLevels.SLIGHTLY_ABOVE_AVERAGE]: "images/chanceLevels/slightly-above-average.svg",
  [ChanceLevels.MODERATELY_ABOVE_AVERAGE]: "images/chanceLevels/moderately-above-average.svg",
  [ChanceLevels.AVERAGE]: "images/chanceLevels/average.svg",
  [ChanceLevels.SLIGHTLY_BELOW_AVERAGE]: "images/chanceLevels/slightly-below-average.svg",
  [ChanceLevels.MODERATELY_BELOW_AVERAGE]: "images/chanceLevels/moderately-below-average.svg",
  [ChanceLevels.SIGNIFICANTLY_BELOW_AVERAGE]: "images/chanceLevels/significantly-below-average.svg",
}

export const COUNTRIES = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Aland Islands', value: 'AX' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, The Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: "Cote D'Ivoire", value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
  { label: 'Holy See (Vatican City State)', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran, Islamic Republic Of', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: "Korea, Democratic People'S Republic of", value: 'KP' },
  { label: 'Korea, Republic of', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: "Lao People's Democratic Republic", value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libyan Arab Jamahiriya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia, Federated States of', value: 'FM' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Netherlands Antilles', value: 'AN' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestine', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Reunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Saint Helena', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia and Montenegro', value: 'CS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Surilabel', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan, Province of China', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Vietnam', value: 'VN' },
  { label: 'Virgin Islands, British', value: 'VG' },
  { label: 'Virgin Islands, U.S.', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
]

interface WeightedItem<T> {
  item: T;
  cumulativeWeight: number;
}
  
const buildWeightedList = <T>(items: T[], weights: number[]): WeightedItem<T>[] => {
  let cumulativeWeight = 0;
  return items.map((item, index) => {
    cumulativeWeight += weights[index];
    return { item, cumulativeWeight };
  });
};

const binarySearch = <T>(weightedItems: WeightedItem<T>[], value: number): T => {
  let start = 0;
  let end = weightedItems.length - 1;

  while (start <= end) {
    const mid = Math.floor((start + end) / 2);
    if (weightedItems[mid].cumulativeWeight < value) {
      start = mid + 1;
    } else if (weightedItems[mid].cumulativeWeight > value && (mid === 0 || weightedItems[mid - 1].cumulativeWeight < value)) {
      return weightedItems[mid].item;
    } else {
      end = mid - 1;
    }
  }

  throw new Error('Failed to get a sample');
};

function sampleFromWeightedList<T>(weightedItems: WeightedItem<T>[]): T {
  const max = weightedItems[weightedItems.length - 1].cumulativeWeight;
  const random = Math.random() * max;
  return binarySearch(weightedItems, random);
}

export function sample<T>(items: T[], weights: number[]): T {
  const weightedItems = buildWeightedList(items, weights);
  return sampleFromWeightedList(weightedItems);
}

export function sampleWithoutReplacement<T>(items: T[], weights: number[], count: number): T[] {
  const weightedItems = buildWeightedList(items, weights);
  const result: T[] = [];
  for (let i = 0; i < count; i++) {
    let sample = sampleFromWeightedList(weightedItems);
    while (result.includes(sample)) {
      sample = sampleFromWeightedList(weightedItems);
    }
    result.push(sample);
  }
  return result;
}

export function shuffleArray<T>(array: T[]): T[] {
  const result = [...array];
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }
  return result;
}
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CardPair } from "../components/Card";
import { Title } from "../components/Text";
import { Dots } from "../components/index";
import { useStore } from "../hooks/useStore";
import { useModalStore } from "../hooks/useModalStore";
import { NUM_PAIRS } from "@tinker-tots/shared/constants";
import { Loading } from "../components/Loading";
import { BeforeLeaveModal } from "./BeforeLeaveModal.jsx";

export function Game() {
  const { nextPath } = useLoaderData();
  // use a ref here for sychronous unblocking on completion
  const blockNavigationRef = useRef(true);
  const processedPairsRef = useRef(new Set());
  const loading = useStore((state) => state.loading);
  const cardPairs = useStore((state) => state.pairs);
  const selectCard = useStore((state) => state.selectCardFromPair);
  const triggerWarningViewed = useStore((state) => state.triggerWarningViewed);
  const openTriggerWarningModal = useModalStore(
    (state) => state.openTriggerWarningModal
  );
  const navigate = useNavigate();
  const onNext = useCallback(() => navigate(nextPath), [navigate, nextPath]);

  const completeAndNext = useCallback(() => {
    blockNavigationRef.current = false;
    onNext();
  }, [onNext]);

  const [pairNumber, setPairNumber] = useState(
    () => cardPairs.findIndex((pair) => !pair.some((c) => c.chosen)) || 0
  );

  useEffect(() => {
    if (pairNumber === -1 || pairNumber >= NUM_PAIRS) {
      completeAndNext();
    }
  }, [pairNumber, completeAndNext]);

  const { t } = useTranslation("game");

  useEffect(() => {
    if (!triggerWarningViewed) {
      openTriggerWarningModal();
    }
  }, [triggerWarningViewed, openTriggerWarningModal]);

  const onSelect = (choice) => {
    if (
      processedPairsRef.current.has(pairNumber) ||
      cardPairs[pairNumber].some((c) => c.chosen)
    ) {
      return;
    }
    processedPairsRef.current.add(pairNumber);
    selectCard({ chosen: choice, pairNumber });

    if (pairNumber < NUM_PAIRS - 1) {
      setPairNumber((n) => n + 1);
    } else {
      completeAndNext();
    }
  };

  if (loading || !cardPairs?.[pairNumber]) return <Loading />;
  return (
    <>
      <BeforeLeaveModal enableBlockerRef={blockNavigationRef} />
      <MobileGame
        pair={cardPairs[pairNumber]}
        pairNumber={pairNumber}
        onSelect={onSelect}
        t={t}
      />
      <DesktopGame
        pair={cardPairs[pairNumber]}
        pairNumber={pairNumber}
        onSelect={onSelect}
        t={t}
      />
    </>
  );
}

const MobileGame = ({ pair, pairNumber, onSelect, deselect, onNext, t }) => {
  const setPresentationTimeStamp = useStore(
    (state) => state.setPresentationTimeStamp
  );
  const setTaskDescriptionShown = useStore(
    (state) => state.setTaskDescriptionShown
  );

  useEffect(() => {
    setPresentationTimeStamp({
      setIndex: pairNumber,
      presentationTimeStamp: Date.now(),
    });
  }, [pairNumber, setPresentationTimeStamp]);

  return (
    <div className="sm:hidden max-w-343px m-auto">
      <div className="font-700 text-4.5 leading-6 text-center">
        {t("title")}
      </div>
      <CardPair
        pair={pair}
        onSelect={onSelect}
        onShowDescription={() =>
          setTaskDescriptionShown({ setIndex: pairNumber })
        }
      />
      <Dots count={NUM_PAIRS} active={pairNumber} onClick={onNext} />
    </div>
  );
};

const DesktopGame = ({ pair, pairNumber, onSelect, t }) => {
  const setPresentationTimeStamp = useStore(
    (state) => state.setPresentationTimeStamp
  );
  const setTaskDescriptionShown = useStore(
    (state) => state.setTaskDescriptionShown
  );

  useEffect(() => {
    setPresentationTimeStamp({
      setIndex: pairNumber,
      presentationTimeStamp: Date.now(),
    });
  }, [pairNumber, setPresentationTimeStamp]);

  return (
    <div className="hidden sm:block sm:w-868px m-auto sm:pt-18">
      <Title text={t("title")} />
      <CardPair
        pair={pair}
        onSelect={onSelect}
        onShowDescription={() =>
          setTaskDescriptionShown({ setIndex: pairNumber })
        }
      />
      <Dots count={NUM_PAIRS} active={pairNumber} />
    </div>
  );
};

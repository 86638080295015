import React from "react";
import { useTranslation } from "react-i18next";

import { attributesMap, AttributeTypes } from "@tinker-tots/shared/constants";

const forTranslation = (s) => s.toLowerCase().replace(/ /g, "-");
const Row = ({ title, items }) => {
  const { t } = useTranslation("card");
  return (
    <div>
      <div className="text-8 font-600 py-4">{title}</div>
      <div className="flex flex-col gap-2">
        {items.map(({ image, label, priority }) => (
          <div
            key={label}
            className={`flex flex-row items-center min-w-128px min-h-128px mx-2 ${
              priority === "essential" ? "bg-tinkerTots-primary-10" : ""
            }`}
          >
            <img
              className="h-80px w-80px"
              src={image}
              alt={t(`${forTranslation(title)}.${forTranslation(label)}.label`)}
            />
            <h2 className="px-4 w-196px break-words hyphens-auto">
              {t(`${forTranslation(title)}.${forTranslation(label)}.label`)}
            </h2>
            <h2 className="px-4 w-496px break-words hyphens-auto">
              {t(
                `${forTranslation(title)}.${forTranslation(label)}.description`
              )}
            </h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export function Traits() {
  return (
    <div className="flex flex-col justify-between min-h-120">
      <Row
        title="Traits"
        items={Object.values(attributesMap).filter(
          (item) => item.type === AttributeTypes.TRAIT
        )}
      />
      <Row
        title="Conditions"
        items={Object.values(attributesMap).filter(
          (item) => item.type === AttributeTypes.CONDITION
        )}
      />
      <div className="h-8" />
      <div className="flex justify-start items-center">
        <span>essential = </span>
        <div className="ml-2 w-8 h-8 bg-tinkerTots-primary-10" />
      </div>
    </div>
  );
}

import React, { Suspense } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
  useLocation,
  Outlet,
  redirect,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./services/i18n";
import { MenuBar } from "./components/MenuBar";
import { Consent } from "./containers/Consent";
import { Game } from "./containers/Game";
import { GamePhase2 } from "./containers/GamePhase2";
import { About } from "./containers/About";
import { FAQ } from "./containers/FAQ";
import { FeedbackModal } from "./containers/Feedback";
import { InstructionsModal } from "./containers/Instructions";
import { TriggerWarningModal } from "./containers/TriggerWarning";
import { SocialShareModal } from "./containers/SocialShare.jsx";
import { Landing } from "./containers/Landing";
import { SurveyOffer } from "./containers/SurveyOffer";
import { Survey } from "./containers/Survey";
import { NotFound } from "./containers/404";
import { useStore } from "./hooks/useStore";

import {
  genderImages,
  attributesMap,
  chanceLevelImages,
  NUM_PAIRS,
  NUM_COMPARISONS,
} from "@tinker-tots/shared/constants";
import useImagePreload from "./hooks/useImagePreload";
import { Loading } from "./components/Loading";
import { Traits } from "./containers/Traits";
import { ResultsSummary } from "./containers/ResultsSummary";
import { getScores } from "./api";

const imagesForPreload = [
  ...Object.values(genderImages),
  ...Object.values(attributesMap).map((attribute) => attribute.image),
  ...Object.values(chanceLevelImages),
];

function Layout() {
  const location = useLocation();

  useImagePreload(imagesForPreload);

  return (
    <div className="h-[100svh] w-screen max-h-[100svh] overflow-x-scroll flex flex-col bg-tinkerTots-gray-50 font-sans text-tinkerTots-black">
      <div className="relative z-50 max-w-screen-xxl mx-auto md:w-full min-w-375px bg-tinkerTots-gray-50 fixed top-0">
        <MenuBar />
      </div>
      <div
        key={location.pathname}
        className="overflow-y-scroll  w-full flex-grow flex flex-col"
      >
        <div className="max-w-screen-xxl mx-auto md:w-full min-w-375px bg-tinkerTots-gray-50 flex-grow flex flex-col">
          <Suspense fallback={<Loading />}>
            <InstructionsModal />
            <TriggerWarningModal />
            <FeedbackModal />
            <SocialShareModal />
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          </Suspense>
        </div>
      </div>
    </div>
  );
}
function ErrorBoundary() {
  const error = useRouteError();
  const { t } = useTranslation("app");
  console.log(error);

  return (
    <div className="flex flex-col items-center justify-center h-full max-h-[100svh] text-6 text-tinkerTots-primary-main">
      <h2 className="font-700 text-8">{t("Oops! Something went wrong.")}</h2>
      <p>{t("We apologize for the inconvenience.")}</p>
      <p>{t("Please try refreshing the page or come back later.")}</p>
    </div>
  );
}
var router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    loader: async () => {
      const { initUser } = useStore.getState();
      console.log("running init user");
      initUser();
      return {};
    },
    shouldRevalidate: () => false,
    children: [
      { index: true, element: <Landing /> },
      { path: "about", element: <About /> },
      { path: "faq", element: <FAQ /> },
      {
        path: "consent",
        element: <Consent />,
        loader: async () => {
          await Promise.all([
            useStore.getState().userPromise,
            useStore.getState().sessionPromise,
          ]);
          const { pairsFirst } = useStore.getState();
          const nextPath = pairsFirst ? "/play" : "/play-2";
          return { nextPath };
        },
      },
      {
        path: "play",
        element: <Game />,
        loader: async () => {
          await useStore.getState().sessionPromise;
          const { consent, pairsFirst, surveyFirst, pairs } =
            useStore.getState();
          if (!consent) {
            throw redirect("/consent");
          }
          const nextPath = pairsFirst
            ? "/play-2"
            : surveyFirst
            ? "/survey-offer"
            : "/results";
          if (pairs?.every((pair) => pair.some((c) => c.chosen))) {
            throw redirect(nextPath);
          }
          return { nextPath };
        },
      },
      {
        path: "play-2",
        element: <GamePhase2 />,
        loader: async () => {
          await useStore.getState().sessionPromise;
          const { consent, comparisons, pairsFirst, surveyFirst } =
            useStore.getState();
          if (!consent) throw redirect("/consent");
          const nextPath = !pairsFirst
            ? "/play"
            : surveyFirst
            ? "/survey-offer"
            : "/results";
          if (comparisons?.every((c) => c.answered)) {
            throw redirect(nextPath);
          }
          return {
            nextPath,
          };
        },
      },
      {
        path: "survey-offer",
        element: <SurveyOffer />,
      },
      {
        path: "survey",
        element: <Survey />,
        loader: async () => ({ nextPath: "/results" }),
      },
      {
        path: "results",
        element: <ResultsSummary />,
        loader: async () => {
          const { pairs, comparisons, pairsFirst } = useStore.getState();
          const pairsComplete =
            pairs?.length === NUM_PAIRS &&
            pairs?.every((pair) => pair.some((c) => c.chosen));
          const comparisonsComplete =
            comparisons?.length === NUM_COMPARISONS &&
            comparisons?.every((c) => c.answered);
          if (!pairsComplete) {
            if (!pairsFirst && !comparisonsComplete) {
              throw redirect("/play-2");
            }
            throw redirect("/play");
          }
          if (!comparisonsComplete) {
            throw redirect("/play-2");
          }
          const attribute1 = pairs[0][0].condition;
          const attribute2 = pairs[0][0].trait;
          return getScores({ attribute1, attribute2 });
        },
      },
      { path: "traits", element: <Traits /> },
      { path: "*", element: <NotFound /> },
    ],
  },
]);

export default function App() {
  return <RouterProvider router={router} fallbackElement={<Loading />} />;
}
